body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.top-div {
  background-color: #553D67;
  padding: 20px;
}

.leftMenu
{
  color: #12343b ;
  background-color: #e1b382;
  width: 20%;
  padding: 20px;
  height: auto;
  overflow:scroll;

}

.topmenu {
  background-color: #2d545e;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-container {
  display: flex;
  justify-content: center; /* Align items horizontally at the center */
}

.horizontal-menu {
  display: flex;
  list-style-type: none;
}

.horizontal-menu > li {
  margin-right: 10px; /* Add some spacing between menu items */
}

.button{
  margin-bottom: '30px';
}


.menu-item:hover {
  color: #adadad; /* Change color on hover */
}

.whiteIcon{
  color: #e1b382;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 0
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.route-segment{
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
  border: 1px solid #000; 
}

.segment-waypoints{
  justify-content:flex-start;
  text-align: left; 
}

.segment-option{
  justify-content: flex-end; /* Aligns items to the right */
  text-align: center; 
}

.leaflet-container.crosshair-cursor-enabled {
  cursor:crosshair;
}